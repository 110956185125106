import { useEffect, useState } from 'react';
import './App.css';
import Board from './Board';

function App() {
  const [draw, setDraw] = useState();
  useEffect(() => {
    fetch(`https://omudrawotl.execute-api.us-east-1.amazonaws.com/note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ operationType: "query" })
    }).then((resp) => {
      return resp.json()

    }).then((json) => {
      setDraw(json.find((note) => note.id === 'a304689e-132e-44be-a431-ac926b7e374d').content);
    })
  }, [])


  return (
    <Board dataURL={draw} />
  );
}

export default App;
